import React from 'react'
import AppBar from '../../Components/AppBar/AppBar'
import './Settings.css'

const Settings = () => {
  return (
    <div className='settings_container' >
        <AppBar/>
        <div>
            
        </div>
    </div>
  )
}

export default Settings