import React,{useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import './DeleteUser.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Seperator from '../../Components/Seperator'
import Display from '../../Components/Display';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import gmail from '../../images/mail.png'
import smartPhone from '../../images/smartphone.png'
import AuthenticationService from '../../services/AuthenticationService'
import Lottie from 'react-lottie';
import { animation } from '../../Constants';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const DeleteUser = () => {

  const [activeTab,setActiveTab] = useState(1)
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const history = useHistory()
    const [messageDisplay,setMessageDisplay] = useState('')
    const [colour,setColour] = useState('red')
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [token,setToken] = useState(null)

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animation.CIRCLE_LOADING,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
  };

    useEffect(()=>{
      getToken()
    },[])

    useEffect(()=>{
      getToken()
    },[isLoggedIn])

    const getToken =async()=>{
      const _token = localStorage.getItem('user')
      if(_token === null){
        setIsLoggedIn(false)
      }else{
        setIsLoggedIn(true)
        setToken(_token)
      }

    }

useEffect(()=>{
  if(messageDisplay != ''){
    toast(messageDisplay)
  }
},[messageDisplay])

      setTimeout(()=>{
        setMessageDisplay('')
      },7000 )

    const emailAccountDeletion = async ()=>{
        setIsLoading(true)
        let user = {
            email
        }
        const userAccountDeletion = await AuthenticationService.delUser(user,token)
        if(userAccountDeletion?.data?.status){
          setIsLoading(false)
          setColour('green')
          setMessageDisplay(userAccountDeletion?.data?.message)
          const result = await localStorage.removeItem('user')
        }else{
          setIsLoading(false)
          setColour('red')
          setMessageDisplay('No user found on this email Id')
        }
    }

    const phoneAccountDeletion = async() => {
        setIsLoading(true)
        let user = {
         phone
        }
        const userAccountDeletion = await AuthenticationService.delUser(user,token)
        if(userAccountDeletion?.data?.status){
          setIsLoading(false)
          setColour('green')
          setMessageDisplay(userAccountDeletion?.data?.status)
          const result = await localStorage.removeItem('user')
          console.log(result);
        }else{
          setIsLoading(false)
          setColour('red')
          setMessageDisplay('No user found on this phone number')
        }
      }

      const emailLogin = async ()=>{
        setIsLoading(true)
        let user = {
            email
        }
        const userLogin = await AuthenticationService.emailLogin(user)
        if( userLogin?.status && !userLogin?.name){
            setIsLoading(false)
            history.push('/user',{email:email})
        }else if(userLogin?.status) {
            setIsLoading(false)
            toast('OTP sent successfully')
            history.push('/otp',{email:email,message:'OTP sent successfully',channel:'delete'})
        }else{
            setIsLoading(false)
            toast('something went wrong');
        }
    }

    const phLogin = async() => {
        setIsLoading(true)
        let user = {
         phone
        }
       if(phone.length === 10){
        const userLogin = await AuthenticationService.mobileLogin(user)
        console.log(userLogin);
       if( userLogin?.status && !userLogin?.name){
        setIsLoading(false)
        history.push('/user',{phone:phone})
       }else if(userLogin?.status) {
        setIsLoading(false)
        toast('OTP sent successfully')
        history.push('/otp',{phone:phone,channel:'delete'})
       }else{
        setIsLoading(false)
        toast('something went wrong');
       }
       }else{
        setIsLoading(false)
        toast('please enter 10 digit number');
       }
      }

      const handleHome = ()=>{
        history.push('/')
      }

  return (
    <div className='login_container' >
      <ToastContainer/>
        <div style={{width:'100%',height:'70px',display:'flex',backgroundColor:'whitesmoke',alignItems:'center',justifyContent:'center',padding:'0 0 0 50px'}} >
            <div style={{position:'absolute',left:30}} ><ArrowBackIcon/></div>
            <div>Delete Account</div>
            <div onClick={()=>handleHome()} style={{position:'absolute',right:30,padding:'5px 15px 5px 15px',background:'rgb(30, 29, 29)',color:'white',borderRadius:7,cursor:'pointer'}}> Home</div>
        </div>
        <Seperator height={Display.setHeight(5)} />
        {
          isLoggedIn?
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',height:Display.setHeight(80)}}>
            <div style={{backgroundColor:'whitesmoke',height:Display.setHeight(60),width:400,borderRadius:25,boxShadow:'1px 2px 10px grey'}} >
                
                <div className='acc_del_top-panel' >
                    <div className={activeTab === 1 ?'acc_email-login bg-white' : 'acc_email-login'} onClick={()=>setActiveTab(1)}>
                        Email
                    </div>
                    
                    <div className={activeTab === 2 ?'acc_phone-login bg-white' : 'acc_phone-login'} onClick={()=>setActiveTab(2)}>
                        Phone
                    </div>
                </div>
                <div className='tab-content' >
                    <Seperator height={Display.setHeight(2)} />
                    <div className={activeTab === 1 ? 'emaillogin-content': 'off'} onClick={()=>setActiveTab(1)}>
                        <Seperator height={Display.setHeight(2)} />
                        <img style={{height:80}} src={gmail} alt="" />
                        <h5>Enter your Email Address</h5>
                        <p style={{textAlign:'center',paddingRight:20,paddingLeft:20}}>Please provide your email address for confirm deletion.</p>
                        <TextField sx={{width:350}} id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>setEmail(e.target.value)} />
                        <Seperator height={Display.setHeight(3)} />
                        {messageDisplay? <div style={{color : colour === 'green' ? 'green' : 'red'}} >{messageDisplay}</div> : <div></div>}
                        <Seperator height={Display.setHeight(3)} />
                        <Button style={{height:40,width:180}} variant="contained" size="medium" color='warning' onClick={()=>emailAccountDeletion()}>{
                            isLoading?
                            <Lottie options={defaultOptions} height={40} width={40}  />
                            :
                            'DELETE ACCOUNT'
                        }</Button>
                    </div>
                    <div className={activeTab === 2 ? 'phonelogin-content' : 'off'} onClick={()=>setActiveTab(2)} >
                    <Seperator height={Display.setHeight(2)} />
                        <img style={{height:80}} src={smartPhone} alt="" />
                        <h5>Enter your Phone Number</h5>
                        <p style={{textAlign:'center',paddingRight:20,paddingLeft:20}}>Please provide your phone number for confirm deletion.</p>
                        <TextField sx={{width:350}} id="outlined-basic" label="Phone Number" variant="outlined" onChange={(e)=>setPhone(e.target.value)} />
                        <Seperator height={Display.setHeight(3)} />
                        {messageDisplay? <div style={{color : colour === 'green' ? 'green' : 'red'}} >{messageDisplay}</div> : <div></div>}
                        <Seperator height={Display.setHeight(3)} />
                        <Button style={{height:40,width:180}} variant="contained" size="medium" color='warning' onClick={()=>phoneAccountDeletion()}>{
                            isLoading?
                            <Lottie options={defaultOptions} height={40} width={40}  />
                            :
                            'Delete Account'
                        }</Button>
                    </div>
                </div>
            </div>

        </div>
        :
        <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center'}}>
          <h1>Please login first to delete your account. </h1>
          <Seperator height={25} />
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',height:Display.setHeight(80)}}>
            <div style={{backgroundColor:'whitesmoke',height:Display.setHeight(60),width:400,borderRadius:25,boxShadow:'1px 2px 10px grey'}} >
                
                <div className='acc_del_top-panel' >
                    <div className={activeTab === 1 ?'acc_email-login bg-white' : 'acc_email-login'} onClick={()=>setActiveTab(1)}>
                        Email
                    </div>
                    
                    <div className={activeTab === 2 ?'acc_phone-login bg-white' : 'acc_phone-login'} onClick={()=>setActiveTab(2)}>
                        Phone
                    </div>
                </div>
                <div className='tab-content' >
                    <Seperator height={Display.setHeight(2)} />
                    <div className={activeTab === 1 ? 'emaillogin-content': 'off'} onClick={()=>setActiveTab(1)}>
                        <Seperator height={Display.setHeight(2)} />
                        <img style={{height:80}} src={gmail} alt="" />
                        <h5>Enter your Email Address</h5>
                        <p style={{textAlign:'center',paddingRight:20,paddingLeft:20}}>Please provide your email address for login.</p>
                        <TextField sx={{width:350}} id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>setEmail(e.target.value)} />
                        <Seperator height={Display.setHeight(3)} />
                        {messageDisplay? <div style={{color : colour === 'green' ? 'green' : 'red'}} >{messageDisplay}</div> : <div></div>}
                        <Seperator height={Display.setHeight(3)} />
                        <Button style={{height:40,width:120}} variant="contained" size="medium" color='warning' onClick={()=>emailLogin()}> 
                        {
                            isLoading?
                            <Lottie options={defaultOptions} height={40} width={40}  />
                            :
                            'Login'
                        }
                        </Button>
                    </div>
                    <div className={activeTab === 2 ? 'phonelogin-content' : 'off'} onClick={()=>setActiveTab(2)} >
                    <Seperator height={Display.setHeight(2)} />
                        <img style={{height:80}} src={smartPhone} alt="" />
                        <h5>Enter your Phone Number</h5>
                        <p style={{textAlign:'center',paddingRight:20,paddingLeft:20}}>Please provide your phone number for confirmation.</p>
                        <TextField sx={{width:350}} id="outlined-basic" label="Phone Number" variant="outlined" onChange={(e)=>setPhone(e.target.value)} />
                        <Seperator height={Display.setHeight(3)} />
                        {messageDisplay? <div style={{color : colour === 'green' ? 'green' : 'red'}} >{messageDisplay}</div> : <div></div>}
                        <Seperator height={Display.setHeight(3)} />
                        <Button style={{height:40,width:120}} variant="contained" size="medium" color='warning' onClick={()=>phLogin()}> 
                        {
                            isLoading?
                            <Lottie options={defaultOptions} height={40} width={40}  />
                            :
                            'Login'
                        }
                        </Button>
                    </div>
                </div>
            </div>

        </div>
        </div>
        }
    </div>
  )
}

export default DeleteUser