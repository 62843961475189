const BACKEND_BASE_URL = 'https://wefix-zgqv.onrender.com'
// const BACKEND_BASE_URL = 'https://localhost:3000'


const BACKEND_API = {
    BASE_API_URL : `${BACKEND_BASE_URL}/api`,
    REGISTER:'/register',
    LOGIN:'/login',
    OTP:'/otp',
    USER_NAME_UPDATE:'/userUpdate',
    USER:'/user',
    REFRESH_TOKEN:'/refresh-token',
    POST:'/post',
    DELETE_USER:'/delete',
    GET_USER: '/get-user'
}

export default {BACKEND_API}